import $ from 'jquery';
//custom-select 
// html :
// .custom-select
//     input#js-custom_select_input
//     .js-fake-input
//     ul
//         li.selected(data-value="blabla") blabla
//         li(data-value="blabla1") blabla1
//         li(data-value="blabla2") blabla2
$(function () {
    function _paginate($paged) {
        var _paged,
            _pagination;
        if ($paged) {
            _paged = $paged;
        } else {
            _paged = 1;
        }
        var _posts_per_page = 18,
            _i = 0;
        $('.template-search-list').each(function () {
            if ($(this).is(":visible")) {
                _i++;
                if (_i > _posts_per_page*_paged || _i <= (_posts_per_page*(_paged-1))){
                    $(this).hide();
                }
            }
        });
        $('#pagination__js').html('');
        if (_i/_posts_per_page > 1) {
            _pagination = '';
            if (_paged > 1) {
                _pagination += '<li class="pagination__item pagination__item--prev"><a class="pagination__link" href="#"><img src="/wp-content/themes/hateamup/shared/img/i-right-green.svg" alt="icon arrow right"></a></li>';
            }
            for (var i = 0; i < _i/_posts_per_page; i++) {
                var _active = '';
                if (_paged == i+1) {
                    _active = ' active';
                }
                _pagination += '<li class="pagination__item'+_active+'"><a class="pagination__link" id="pagination__link" href="#">'+(i+1)+'</a></li>';
            }
            if (_paged < _i/_posts_per_page) {
                _pagination += '<li class="pagination__item"><a class="pagination__link" href=""><img src="/wp-content/themes/hateamup/shared/img/i-right-green.svg" alt="icon arrow right"></a></li>';
            }

            $('#pagination__js').append(_pagination);
        }
        
    }
    _paginate();
    $('.js-custom-select').each(function () {
        var _this = $(this),
            fakeInput = _this.find('.js-fake-input'),
            inputHidden = _this.find('#js-custom-select-input'),
            selected = _this.find('li.selected'),
            errorClass = 'has-error',
            _open = function () {
                _this.addClass('select-open')
            },
            _close = function () {
                _this.removeClass('select-open')
            },
            _changeSlected = function (elem) {
                _this.find('li').removeClass('selected');
                $(elem).addClass('selected');
            },
            _update = function (val,key) {
                val = val || 'selected not found';
                fakeInput.text(val);
                inputHidden.val(val);
                // console.log(inputHidden.val());
                // _validate();
                if(key=='all'){
                    $('.template-search .template-search-list').show();
                }else{
                    $('.template-search .template-search-list').hide();
                    $('.template-search .template-search-list').each(function () {
                        var tag = $(this).attr('data-tag').split(',');
                        if(tag.indexOf(key+'') >= 0){
                            $(this).show();
                        }
                    });
                }
            },
            _validate = function () {
                if (fakeInput.text().trim() == 'タグを選択') {
                    inputHidden.addClass(errorClass);
                    inputHidden.val('');
                } else {
                    inputHidden.removeClass(errorClass);
                }
            },
            _init = function(){
                if (selected.length) {
                    _update(_this.find(selected).data('value'));
                }
            };

        //init
        _init();

        //handle
        fakeInput.on('click', function () {
            _open();
        })

        _this.on('click', 'li', function () {
            var val = $(this).data('value');
            var key = $(this).data('filter');
            _changeSlected(this);
            _update(val,key);
            _close();
            _paginate();
        })

        $(document).on('click touchstart', function (event) {
            // Check if clicked outside target
            if (!($(event.target).closest('.js-custom-select').length)) {
                // Hide target
                _close();
            }
        });
    });
    $(document).on('click', '#pagination__link', function () {
        $('.template-search-list').show();
        $('.pagination__item').removeClass('active');
        $(this).parent().addClass('active');
        var $paged;
        $paged = parseInt($(this).text());
        _paginate($paged);
        return false;
    });
})